<template>
    <div class="pt-4 login-tabs-container">
        <div class="mb-4">
            <h3 class="mb-4">Forgot Password?</h3>
            <p>Enter your registered email to receive password reset instruction.</p>
        </div>
        <div class="pt-2">
            <vs-input
                name="email"
                icon-no-border
                icon="email"
                label-placeholder="Email"
                v-model="email"
                size="large"
                class="w-100"/>
            <p class="text-center mt-4 text-danger" v-if="status === 'error'">{{message}} </p>
            <p class="text-center mt-4 text-success" v-if="status === 'success'">{{message}} </p>
            <div class="d-flex">
                <vs-button :class="status.length > 0 ? 'mx-auto mt-2 pl-4 pr-4' : 'mx-auto mt-4 pl-4 pr-4'" class="" size="large" :disabled="!validateForm" @click="submit">{{submitAction}}</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    name: 'ForgotPassword',
    data () {
    return {
      email: '',
    }
  },
  computed: {
    validateForm () {
      return this.status === 'success' ? true : this.email !== '';
    },
    submitAction() {
        return this.status === 'success' ? 'Login' : 'Submit'
    },
    ...mapState('auth', ['status', 'message'])
  },
  methods: {
    submit () {
        if (this.status === 'success') {
            this.$router.push('/login');
            this.reset();
        } else {
            // Loading
            this.$vs.loading()
            const payload = {
                    email: this.email,
                    closeAnimation: this.$vs.loading.close
            }
            this.forgotPassword(payload);
            //this.$store.dispatch('auth/forgotPassword', payload);
        }
    },
    ...mapActions('auth', ['forgotPassword']),
    ...mapMutations('auth', ['reset'])
  }
}
</script>

<style lang="scss">
    .login-tabs-container {
        min-height: 505px;

        .con-tab {
            padding-bottom: 14px;
        }

        .con-slot-tabs {
            margin-top: 1rem;
        }
    }
</style>